.navbar {
  height: 90px;
  width: 100vw;
}

.container-fluid {
  padding-left: 5vw;
  padding-right: 5vw;
  padding-bottom: 10px;
  width: 100%;
  background-color: var(--main-blue-color);
}

.navbar-brand {
  display: flex;
  align-items: center;
  font-size: 30px;
}

.nav-link {
  font-size: calc(0.5em + 1vw);
  color: white;
  margin-right: 2vw;
}

.nav-link:hover {
  transform: scale(1.1);
}

.btn-warning {
  background-color: #ff5c00;
  color: white;
  font-size: calc(0.5em + 1vw);
  border: #ff5c00;
  white-space: nowrap;
}

.btn-warning:hover {
  background-color: orange;
  color: black;
  transform: scale(1.1);
  transition: all 0.5s ease-out;
}
