.Home {
  text-align: center;
}

.header-content {
  padding-top: min(150px, 30vw);
  width: 100vw;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-around;
}

.compete-like-a-champion-container {
  font-size: min(calc(2px + 5vw), 75px);
  font-weight: bolder;
  text-align: left;
  margin-top: 4vw;
  padding-left: 5vw;
  color: black;
}

.compete-text {
  margin-bottom: -2vw;
}

.champion-text {
  color: var(--main-orange-color);
}

.app-store-btn {
  border-width: 1;
  color: black;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.app-store-btn > img {
  height: min(30px, 5vw);
  margin-right: -3px;
}

.app-store-btn > .button-text-container > .button-title {
  font-size: min(12px, 1.25vw);
}

.app-store-btn > .button-text-container > .button-store-name {
  font-size: min(20px, 1.5vw);
} 

.athletes {
  width: min(65vw, 1000px);
  margin-left: -100px;
}

.icons-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  border-radius: 25px;
  margin: 0 2vw;
  padding: 0.5vw;
  background: var(--main-blue-color);
}

.phone-models {
  width: max(350px, 70vw);
}