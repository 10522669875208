.title {
  color: white;
  font-weight: bolder;
  font-size: min(2vw, 20px);
}

.icon {
  height: min(5vw, 75px);
  margin: min(0.5vw, 5px);
}
