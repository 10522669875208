.card {
  width: max(300px, 35vw);
  border: 0;
  border-radius: 20px;
  background: transparent;
}

.card:hover {
  transform: scale(1.02);
  transition: all 0.2s ease-out;
  cursor: pointer;
}

.card-front-title {
  height: max(150px, 16vw);
  display: flex;
  align-items: center;
  width: 100%;
  background-color: var(--main-orange-color);
  box-shadow: rgba(0, 0, 0, 0.75) 0px 5px 15px;
  border-radius: 20px;
  padding: max(20px, 2vw);
}

.headshot {
  width: 45%;
  border-radius: 20px;
  transition: all 0.5s ease;
}

.header-text {
  text-align: left;
  padding-left: 1em;
}

.name {
  font-size: calc(1em + 1.5vw);
  font-weight: bolder;
  color: white;
}

.position {
  color: white;
}

.card-back-bio {
  width: 100%;
  min-height: max(150px, 16vw);
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  background-color: rgb(33, 37, 41);
  box-shadow: rgba(0, 0, 0, 0.75) 0px 5px 15px;
  border-radius: 20px;
  padding: max(20px, 2vw);
}

.info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 1vw;
  padding-right: 1vw;
  font-size: calc(0.6em + 0.3vw);
}

.info-label {
  color: #ff5c00;
  font-weight: bolder;
}

.info-icon {
  margin-right: 1em;
}

.info-text {
  width: 60%;
  color: white;
}
