.Contact {
  text-align: center;
  padding-top: 20vh;
  min-height: 100vh;
}

.Contact-header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  justify-content: flex-start;
  padding-left: 5vw;
  padding-right: 5vw;
  padding-bottom: 50px;
}

.contact-us-header {
  font-size: calc(25px + 2vmin);
  font-weight: bolder;
  border-left: thick solid var(--main-orange-color);
  padding-left: 0.5em;
}

.us-text {
  color: var(--main-orange-color);
}

.Contact-header > p {
  font-size: 20px;
}