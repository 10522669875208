.footnote-container {
  width: 100vw;
  padding-left: 2vw;
  padding-right: 2vw;
  padding-top: 5px;
  background: var(--main-blue-color);
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.ready-for-your-next-championship-container {
  font-size: calc(0.9em + 1vw);
  text-align: left;
  padding-left: 10px;
  width: 40vw;
}

.ready-text {
  color: white;
  font-weight: bolder;
  vertical-align: baseline;
  margin-bottom: -5px;
  padding-bottom: -5px;
}

.championship-text {
  color: var(--main-orange-color);
  font-weight: bolder;
}

.home-blurb-container {
  color: white;
  font-size: min(calc(0.4em + 1vw), 20px);
  width: 50vw;
  text-align: right;
  padding-right: 20px;
}
