.footer-container {
  width: 100vw;
  padding-left: 2vw;
  padding-right: 2vw;
  background: var(--main-blue-color);
  display: flex;
  justify-content: space-between;
  align-items: last baseline;
  flex-wrap: wrap;
}

.btn {
  padding: min(20px, 1vw);
}

.copyright-container {
  font-size: min(calc(0.4em + 1vw), 15px);
  color: white;
}

.terms-conditions-container {
  font-size: min(calc(0.4em + 1vw), 15px);
  padding-right: 20px;
  color: white;
}

.btn-transparent:hover {
  color: white;
  transform: scale(1.2);
}
